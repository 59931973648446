.base-image {
    max-width: 100%;
    height: auto;
}

.brightness2 {
    background-color: white;
    display: inline-block;
    transition: background-color 0.3s, opacity 0.3s;
  }

.brightness2:hover {
  background-color: lightgray;
  opacity: 0.8;
}

@font-face {
  font-family: 'OldRepublic';
  src: url('../../static/fonts/old_r.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.oldr-font {
  font-family: 'OldRepublic', sans-serif;
  font-size: 0.6em;
  color: #3e3e3e;
}